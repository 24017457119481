import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Timer from "app/pages/.shared/Timer";
import "./DealTimer.scss";

const DealTimer = ({ hour, day }) => {
	const isDealExpired = hour === 0 && day === 0;

	const dealNode = !isDealExpired ? (
		<Timer hour={hour} day={day} suffix={<FormattedMessage id="timer.left.label" />} />
	) : (
		<div className="deal-timer__status">
			<FormattedMessage id="product.deal.expired.label" />
		</div>
	);

	return <div className="deal-timer">{dealNode}</div>;
};

DealTimer.propTypes = {
	hour: PropTypes.number,
	day: PropTypes.number,
};

export default React.memo(DealTimer);
