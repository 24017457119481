import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import env from "app/utils/env";

const useClickToCall = ({ brandCode, marketingCode, shopCode, isAuthenticated, saveProfile }) => {
	const [isCallCenterAvailable, setCallCenterAvailable] = useState(true);

	//axios calls

	const postCreateCall = useCallback(
		async ({ phoneNumber }) => {
			return await axios.post(`${env("CLICK_TO_CALL_API_URL")}/create`, {
				brandCode,
				marketingCode,
				shopCode: shopCode,
				phoneNumber,
			});
		},
		[brandCode, marketingCode, shopCode]
	);

	const fetchCallCenterAvailability = useCallback(async () => {
		await axios
			.get(`${env("CLICK_TO_CALL_API_URL")}/available`, {
				params: {
					brandCode,
					marketingCode,
					shopCode: shopCode,
				},
			})
			.then(response => {
				return setCallCenterAvailable(response?.data?.available);
			});
		return status;
	}, [brandCode, marketingCode, shopCode]);

	//Fetch the CallCenter Availability when opening the modal

	useEffect(() => {
		fetchCallCenterAvailability();
	}, [brandCode, marketingCode, shopCode]);

	//Method to handle the submition of the call

	const requestCall = (values, formikBag) => {
		formikBag.setStatus({ success: undefined });
		if (isAuthenticated) {
			saveProfile({ values, isSilent: true });
		}
		postCreateCall({
			phoneNumber: values?.phone?.replace("+", ""),
		})
			.then(res => {
				if (res.status === 200 && res?.data?.callId) {
					formikBag.setStatus({ success: true });
				} else {
					formikBag.setStatus({ success: false });
				}
			})
			.catch(error => {
				formikBag.setStatus({ success: false });
			});
	};

	return {
		isCallCenterAvailable,
		requestCall,
	};
};

export default useClickToCall;
