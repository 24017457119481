import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { getProfile, saveProfile } from "app/pages/Account/Profile/profileActionCreators";
import { parsePhoneNumber } from "react-phone-number-input/max";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import useClickToCall from "./useClickToCall";
import PropTypes from "prop-types";

import ClickToCall from "./ClickToCall";

const ClickToCallContainer = props => {
	const { brandCode, marketingCode, shop, isAuthenticated, getProfile, saveProfile } = props;

	useEffect(() => {
		if (isAuthenticated) {
			getProfile();
		}
	}, [isAuthenticated]);

	const useClickToCallProps = useClickToCall({
		brandCode,
		marketingCode,
		shopCode: shop,
		isAuthenticated,
		saveProfile,
	});

	return <ClickToCall {...useClickToCallProps} {...props} />;
};

ClickToCallContainer.propTypes = {
	shop: PropTypes.string,
	brandCode: PropTypes.string,
	marketingCode: PropTypes.string,
	initialValues: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	saveProfile: PropTypes.func,
	getProfile: PropTypes.func,
};

const mapStateToProps = state => {
	let numberAnalysis = { number: "" };

	if (state?.profile?.address?.phone?.length) {
		numberAnalysis = parsePhoneNumber(state.profile.address.phone, state.shop.slice(3, 5));
	}

	const initialValues = {
		phone: numberAnalysis.number,
	};

	const brandCode = state.brand?.code;
	const shop = state.shop;
	let marketingCode;
	if (brandCode && shop) {
		marketingCode = brandCode + shop.slice(-2);
	}

	return {
		shop,
		initialValues,
		brandCode,
		marketingCode,
		isAuthenticated: isAuthenticated(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ getProfile, saveProfile }, dispatch);
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ClickToCallContainer)
);
