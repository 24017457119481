import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { FormattedMessage } from "react-intl";
import CouponsCguPopoverButton from "app/pages/Booking/Payment/PaymentCoupons/CouponsCGUPopoverButton/CouponsCGUPopoverButton";
import { useRouteMatch, useLocation } from "react-router-dom";
import "./CouponsBanner.scss";
import { sendTagOnCouponBannerClick } from "app/utils/analytics";

const CouponsBanner = ({ creditNotesAmount }) => {
	const checkRoute = ["listing", "product", "quote", "merch", "search"].find(page => {
		const match = useRouteMatch();
		const location = useLocation();
		return (
			location.pathname.includes(page) ||
			(page === "product" && match.path.includes(":productUri"))
		);
	});

	const handleOnCGUClick = useCallback(() => {
		sendTagOnCouponBannerClick();
	}, []);

	return checkRoute && creditNotesAmount > 0 ? (
		<div className="coupons-banner">
			<FormattedMessage
				id="mycoupons.creditnotes.banner.text"
				values={{
					amount: <AmountContainer amount={creditNotesAmount} />,
				}}
				tagName="span"
			/>
			<CouponsCguPopoverButton
				hasCreditNotes={true}
				isDetailed={true}
				onClick={handleOnCGUClick}
			/>
		</div>
	) : null;
};

CouponsBanner.propTypes = {
	creditNotesAmount: PropTypes.number,
};

export default React.memo(CouponsBanner);
