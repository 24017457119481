import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import Popover from "react-popover";
import { PASSENGER_TYPE } from "app/constants";
import DateSelector from "app/pages/.shared/DateSelector/DateSelector";
import "./DateSelectorInput.scss";
import { injectIntl } from "react-intl";
import get from "lodash/get";

class DateSelectorInput extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isPopoverOpen: false,
			disabled: props.disabled,
		};
		this.showPopover = this.showPopover.bind(this);
		this.closePopover = this.closePopover.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.renderDate = this.renderDate.bind(this);
		this.handleCrossClick = this.handleCrossClick.bind(this);
	}

	closePopover() {
		this.setState({
			isPopoverOpen: false,
			disabled: false,
		});
	}

	showPopover() {
		this.setState({
			isPopoverOpen: true,
			disabled: true,
		});
	}

	handleCrossClick() {
		if (
			this.props.fields &&
			(!this.props.fields.day.value ||
				!this.props.fields.month.value ||
				!this.props.fields.year.value)
		) {
			this.props.fields.day.onChange(undefined);
			this.props.fields.month.onChange(undefined);
			this.props.fields.year.onChange(undefined);
		}

		this.closePopover();
	}

	onDateChange(date) {
		if (this.props.fields) {
			this.props.fields.day.onChange(date.day);
			this.props.fields.month.onChange(date.month);
			this.props.fields.year.onChange(date.year);
		}

		if (date.year) {
			this.setState(
				{
					isPopoverOpen: false,
					disabled: false,
				},
				() => {
					if (typeof this.props.onChange === "function") {
						this.props.onChange({ ...date });
					}
				}
			);
		}
	}

	renderDate(day, month, year) {
		const format = {};
		let renderDay;
		let renderMonth;
		let renderYear;

		if (day) {
			format.day = "2-digit";
			renderDay = day;
		} else {
			renderDay = 1;
		}

		if (month !== "" && month >= 0) {
			format.month = this.props.formatMonth;
			renderMonth = month;
		} else {
			renderMonth = 0;
		}

		if (year) {
			format.year = "numeric";
			renderYear = year;
		} else {
			// c'est une annee arbitraire ayant un 29 fevrier. prendre 1972 provoque un bug sur safari ou le jour est minoré de 1
			// voir https://github.com/yahoo/react-intl/issues/991
			renderYear = 1984;
		}

		if (day || month || year) {
			// on ne positionne pas sur un horaire de 00:00:00 pour éviter les switchs de date (ex: 31 mars 1969 serait repositionner en 30 mars 1969)
			return this.props.intl.formatDate(
				new Date(renderYear, renderMonth, renderDay, 15, 0, 0),
				format
			);
		}
		return new Date();
	}

	handleOuterPopOver = () => {
		this.closePopover();
	};

	render() {
		const {
			fields = {},
			label,
			name,
			id,
			type = PASSENGER_TYPE.ADULT,
			isChildrenAgeRequired,
			isHidden = false,
			isRequired = false,
			...props
		} = this.props;

		const isAnyDateValues =
			get(fields, "year.value") || get(fields, "month.value") || get(fields, "day.value");

		const isError =
			get(fields, "year.touched") &&
			get(fields, "month.touched") &&
			get(fields, "day.touched") &&
			get(fields, "year.error") &&
			get(fields, "month.error") &&
			get(fields, "day.error");

		const inputClassName = classNames({
			"control-group": true,
			"control-group--required": isRequired,
			"control-group--touched": isAnyDateValues,
			"control-group--error": isError,
			"control-group--disabled": this.state.disabled,
		});

		const thisYear = new Date().getFullYear();

		let maxYear = isChildrenAgeRequired ? thisYear - 18 : thisYear - 12;
		let minYear = 1900;

		if (type === PASSENGER_TYPE.CHILD) {
			// enfant entre 2 et 11 ans
			maxYear = thisYear - 1;
			minYear = isChildrenAgeRequired ? thisYear - 19 : thisYear - 13;
		} else if (type === PASSENGER_TYPE.INFANT) {
			// enfant entre 0 et 2 ans
			maxYear = thisYear;
			minYear = thisYear - 3;
		}

		let computedValue;

		if (isAnyDateValues) {
			computedValue = this.renderDate(
				fields.day.value,
				fields.month.value,
				fields.year.value
			);
		} else {
			computedValue = "";
		}

		return isHidden ? (
			false
		) : (
			<div className="date-selector-input" data-cy={props["data-cy"]}>
				<Popover
					className="date-selector-input__popover"
					body={
						<div className="date-selector-input__date-selector">
							<div className="date-selector-input__close">
								<i
									className="icon icon--cross-dark"
									onClick={this.handleCrossClick}
								/>
							</div>
							<DateSelector
								maxYear={maxYear}
								minYear={minYear}
								onChange={this.onDateChange}
							/>
						</div>
					}
					isOpen={this.state.isPopoverOpen}
					preferPlace="below"
					place="below"
					onOuterAction={this.handleOuterPopOver}
				>
					<div className={inputClassName}>
						<label htmlFor={id} className="control-group__label">
							{label}
						</label>

						<input
							{...fields}
							className="control-group__input"
							type="text"
							id={id}
							name={name}
							value={computedValue}
							onFocus={this.showPopover}
							disabled={this.state.disabled}
							readOnly="true"
						/>
					</div>
				</Popover>
			</div>
		);
	}
}

DateSelectorInput.propTypes = {
	intl: PropTypes.object,
	name: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	id: PropTypes.string.isRequired,
	isChildrenAgeRequired: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	isHidden: PropTypes.bool,
	isRequired: PropTypes.bool,
	fields: PropTypes.object,
	formatMonth: PropTypes.oneOf(["2-digit", "long"]),
	type: PropTypes.oneOf([PASSENGER_TYPE.CHILD, PASSENGER_TYPE.INFANT, PASSENGER_TYPE.ADULT]),
};

DateSelectorInput.defaultProps = {
	formatMonth: "long",
};

export default injectIntl(DateSelectorInput);
