import React from "react";
import { FormattedMessage } from "react-intl";
import "./cookie-policy-footer.scss";
import PropTypes from "prop-types";

class CookiePolicyFooter extends React.PureComponent {
	constructor() {
		super();
		this.hideFooter = this.hideFooter.bind(this);
	}

	hideFooter() {
		this.props.acceptCookyPolicy();
	}

	render() {
		const {
			showCookieFooter = false,
			confidentialiteDocumentName,
			brandDisplayName,
		} = this.props;

		return (
			showCookieFooter && (
				<div className="cookie-policy-footer">
					<div className="cookie-policy-footer__content">
						<FormattedMessage
							values={{
								brandName: brandDisplayName,
								linkToCGV: (
									<a
										className="relative-link"
										rel="noopener noreferrer"
										target="_blank"
										href={confidentialiteDocumentName}
									>
										<FormattedMessage id="cookie.policy.cgv.link" />
									</a>
								),
							}}
							id="cookie.policy.footer.message"
							tagName={"div"}
						/>

						<i className="icon icon--circle-cross-white" onClick={this.hideFooter} />
					</div>
				</div>
			)
		);
	}
}

CookiePolicyFooter.propTypes = {
	acceptCookyPolicy: PropTypes.func,
	showCookieFooter: PropTypes.bool,
	confidentialiteDocumentName: PropTypes.string,
	brandDisplayName: PropTypes.string,
};

export default CookiePolicyFooter;
