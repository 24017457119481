import { connect } from "react-redux";
import CouponsBanner from "app/pages/.shared/CouponsBanner/CouponsBanner";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
} from "app/pages/Account/MyCoupons/couponSelector";

const mapStateToProps = state => {
	return {
		creditsAmount: getAvailableCreditsTotal(state),
		creditNotesAmount: getAvailableCreditNotesTotal(state),
	};
};

export default connect(mapStateToProps)(CouponsBanner);
