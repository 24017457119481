import PropTypes from "prop-types";
import React, { useContext } from "react";
import HelpDeskPhoneNumberContainer from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumberContainer.jsx";
import { FormattedMessage } from "react-intl";
import "./ContactUs.scss";
import AppGlobalsContext from "app/AppGlobalsContext";
import { BRANDS } from "app/constants";

const ContactUs = ({ productReference }) => {
	const { brand } = useContext(AppGlobalsContext);

	return (
		<div className="contact-us">
			<div className="contact-us__title">
				<FormattedMessage id="general.need.help.title" />
			</div>

			<div className="contact-us__phone">
				<HelpDeskPhoneNumberContainer useSVA={brand === BRANDS.CD} showPicto={true} />
			</div>

			<div className="contact-us__product-reference">
				<FormattedMessage
					values={{
						reference: productReference,
					}}
					id="general.product.reference"
				/>
			</div>
		</div>
	);
};

ContactUs.propTypes = {
	productReference: PropTypes.string,
};

export default React.memo(ContactUs);
