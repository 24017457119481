import { connect } from "react-redux";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import { bindActionCreators } from "redux";
import CookiePolicyFooter from "app/pages/.shared/CookiePolicyFooter/CookiePolicyFooter";
import { getBrandDisplayName } from "app/reducers/partnerSelector";

const mapStateToProps = state => {
	const shop = state.shop;
	const disableCookieBanner = state.partner.disableCookieBanner;
	return {
		showCookieFooter:
			typeof window !== "undefined" &&
			!disableCookieBanner &&
			shop.slice(3, 5) !== "CH" && // on masque si ce n'est pas un pays de lUE
			!state.isCookiePolicyAccepted &&
			!state.auth.token &&
			!state.navigation.isInApp,
		confidentialiteDocumentName: state.documents.confidentialite,
		brandDisplayName: getBrandDisplayName(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ acceptCookyPolicy }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookiePolicyFooter);
