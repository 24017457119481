import React from "react";
import { FormattedMessage } from "react-intl";
import "./SuccessNotice.scss";

const SuccessNotice = () => {
	return (
		<div className="success-notice">
			<div className="success-notice__checkmark">✓</div>
			<div className="success-notice__text">
				<FormattedMessage id="click.to.call.service.success.label" />
			</div>
		</div>
	);
};

export default SuccessNotice;
