import React, { useRef } from "react";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import validationMessages from "app/utils/form/validationMessages";
import { validatePhoneNumberType } from "app/pages/Account/Profile/profileSchema";

import Button from "app/pages/.shared/form/Button";
import { Form, Formik } from "formik";
import PhoneIntlFormik from "app/pages/.shared/profile/PhoneIntlFormik";
import GlobalErrorMessagesFormik from "app/pages/.shared/form/GlobalErrorMessagesFormik";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { object, string } from "yup";
import { ALERT_TYPE } from "app/constants";

import ServiceUnavailable from "./ServiceUnavailable";
import SuccessNotice from "./SuccessNotice";

import "./ClickToCall.scss";

const validationSchema = object().shape({
	phone: string()
		.test(
			"phone (mobile or a fixed-line number)",
			validationMessages.phoneInvalid,
			numberIntl => validatePhoneNumberType(numberIntl)
		)
		.required(validationMessages.phoneRequired),
});

const defaultValues = {
	phone: "",
};

const ClickToCall = ({ shop, initialValues, isCallCenterAvailable, requestCall }) => {
	return (
		<div className="click-to-call">
			{!isCallCenterAvailable && (
				<div className="click-to-call__unavailable-service">
					<ServiceUnavailable />
				</div>
			)}
			<Formik
				enableReinitialize
				initialStatus={{ success: undefined }}
				validationSchema={validationSchema}
				initialValues={{ ...defaultValues, ...initialValues }}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={requestCall}
			>
				{({
					errors,
					isValid,
					status,
					isSubmitting,
					setFieldValue,
					setFieldTouched,
					setFieldError,
				}) => {
					return (
						<Form
							className={classNames("click-to-call__form", {
								"click-to-call__form--unavailable": !isCallCenterAvailable,
							})}
							onChange={event => {
								// Permet de supprimer l'état en erreur du champ en erreur lorsque le user commence à le  modifier
								// Actuellement pas possible de le faire facilement avec formik
								// https://github.com/formium/formik/issues/2727
								if (!isEmpty(errors)) {
									setFieldError(event.target.name);
								}
							}}
						>
							<div className="click-to-call__instructions">
								<FormattedMessage id="click.to.call.form.instructions" />
							</div>

							<div className="click-to-call__phone">
								<PhoneIntlFormik
									id="phone"
									name="phone"
									shop={shop}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									label={<FormattedMessage id="phone.label" />}
									data-cy="phone-input"
								/>
							</div>

							<div className="click-to-call__footer">
								{!status.success && (
									<Button
										design="primary"
										submit
										className="click-to-call__button"
										loading={isSubmitting}
										data-cy="create-call-button"
									>
										<FormattedMessage id="click.to.call.button.label" />
									</Button>
								)}
								{!isValid && (
									<GlobalErrorMessagesFormik
										disableScroll={true}
										isValid={isValid}
										isSubmitting={isSubmitting}
										errors={Object.values(errors).map(error => {
											return (
												<FormattedMessage key={error.id} id={error.id} />
											);
										})}
										alertType={ALERT_TYPE.ERROR}
									/>
								)}
								{status.success && <SuccessNotice />}
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

ClickToCall.propTypes = {
	shop: PropTypes.string,
	initialValues: PropTypes.object,
	requestCall: PropTypes.func,
	isCallCenterAvailable: PropTypes.bool,
};

export default React.memo(ClickToCall);
