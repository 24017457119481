import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import HelpDeskPhoneNumberContainer from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumberContainer";
import "./contact-experts.scss";

class ContactOurExperts extends React.PureComponent {
	render() {
		const { message, showFAQLink } = this.props;
		return (
			<div className="contact-experts">
				{message}
				<HelpDeskPhoneNumberContainer showFAQLink={showFAQLink} />
			</div>
		);
	}
}

ContactOurExperts.propTypes = {
	showFAQLink: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ContactOurExperts.defaultProps = {
	message: <FormattedMessage id="confirmation.contact.headline" />,
};

export default ContactOurExperts;
